@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

body {
  font-family: "Lato", sans-serif !important;
}

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  /* --primary-transparent-black: rgb(255, 255, 255); */
  --primary-green: green;
  --primary-dark-bg: rgba(52, 58, 64, 0.9);

  /* --bg-color: linear-gradient(to right, #1c2c26, #373b44); */
  /* --bg-color: linear-gradient(to right, #1d2925, #2c2824); */
  /* --bg-color: linear-gradient(to right, #000000, #2c2427); */
  /* --bg-color: linear-gradient(to right, #414141, #272626); */
  --bg-color: #272626;

  /* --box-grad: linear-gradient(to bottom, #1c2c26, #50535a 90%); */
  /* --box-grad: linear-gradient(to bottom, #0c0f0e, #42413c 95%); */
  /* --box-grad: linear-gradient(to right bottom, #42413c, #000000 100%); */
  --box-grad: linear-gradient(to right bottom, #414141, #1f1c1c 100%);

  --mode-color: black;
  --text-color: white;
  --text-color-2: rgb(211, 197, 197);
  --name: #e0a188;
  --heading: #ff8c80;
  --secondary-heading: rgb(108, 195, 201);
  --secondary-content: #a1a1a1;
  --secondary-content-light: #636363;
  --gallery-transparent-bg: rgba(0, 0, 0, 0.6);

  --header-wall: url("https://res.cloudinary.com/sushant-thombare/image/upload/v1628270833/sushant-portfolio/header-wall_mmqb56.jpg");
  --gallery-wall: url("https://res.cloudinary.com/sushant-thombare/image/upload/v1628270872/sushant-portfolio/gallery-wall_fobzr8.jpg");
}

[data-theme="light"] {
  /* --bg-color: linear-gradient(to right, #b9e0b9, #99f3fd); */
  /* --bg-color: linear-gradient(to right, #b9e0b9, #fdf5f5); */
  /* --bg-color: linear-gradient(to right, #eaf8f1 0%, #fcf9c8 100%); */
  /* --bg-color: linear-gradient(to right, #eaf8f1 0%, #dbfcc8 100%); */
  /* --bg-color: linear-gradient(to right, #ebe6e7 0%, #f3f8c5 100%); */
  --bg-color: #d7ebdd;

  /* --box-grad: linear-gradient(to bottom, #e7fde7, #88fcdf 80%); */
  /* --box-grad: linear-gradient(to bottom, #aad1a9, #effaf7 80%); */
  --box-grad: linear-gradient(to left bottom, #aed1b8, #ffffff 90%);
  /* --box-grad: linear-gradient(to right bottom, #ffffff, #d1ecc0 60%); */
  --mode-color: white;
  --text-color: black;
  --text-color-2: rgb(68, 62, 62);
  --name: #ca4839;
  --heading: #ef4035;
  --secondary-heading: #e97f5f;
  --secondary-content: #535252;
  --secondary-content-light: #9e9d9d;
  /* --gallery-transparent-bg: rgba(100, 151, 156, 0.6); */
  --gallery-transparent-bg: rgba(175, 173, 137, 0.6);
}

.App {
  background-color: var(--page-color);
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* **************** PARTICLES JS ********************** */

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  /* .particles-canvas {
    display: none;
  } */
}

/* **************** GRAD BUTTON ********************** */

.btn-grad {
  background-image: linear-gradient(
    to right,
    #ff6e6e 0%,
    #bfe9ff 51%,
    #ff6e6e 100%
  );
}
.btn-grad {
  /* margin: 10px 0px; */
  font-size: 0.7rem;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.8s;
  background-size: 200% auto;
  color: rgb(255, 255, 255);
  /* box-shadow: 0 0 2px #eee; */
  border-radius: 10px;
  display: block;
  text-decoration: none;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  .btn-grad {
    margin: 15px -20px;
  }
}

.heading-hover:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
}

.btn-outline {
  margin: 0.5rem;
  font-size: 0.7rem;
  padding: 0.6rem 1.3rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  /* background-size: 200% auto; */
  background-color: transparent;
  color: var(--heading);
  /* box-shadow: 0 0 2px #eee; */
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  border: 1px solid var(--heading);
  /* backface-visibility: hidden; */
}

.btn-outline:hover {
  color: var(--text-color) !important;
  border: 1px solid var(--mode-color);
  background: linear-gradient(
    to bottom,
    var(--heading) 0.01%,
    var(--mode-color)
  );
  /* box-shadow: 0 0 1px var(--text-color); */
  /* border: 0px; */
}

@media (max-width: 767px) {
  .btn-outline {
    font-size: 0.6rem;
    margin: 0.6rem;
    padding: 0.6rem 1.1rem;
  }
}

.centered {
  margin: 2rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
