/**************** FOOTER ******************/

.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
}

.footer a:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--heading);
}
