/* **************** Navbar ********************** */

nav {
  z-index: 2;
}

.navbar {
  background: var(--bg-color);
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  opacity: 0.8;
}

/* .bg-my-transparent {
  background-color: var(--primary-dark-bg) !important;
} */

.my-logo {
  font-family: "Righteous", cursive;
  font-size: 0.9rem;
  background: -webkit-linear-gradient(var(--heading), #e7ab98);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-img {
  max-height: 1.5rem;
}

@media (max-width: 768px) {
  .my-logo {
    font-size: 0.8rem;
  }
  .logo-img {
    height: 1.3rem;
  }
}

.logo {
  /* padding-top: 10px; */
  /* width: 6.25rem; */
}

/* Menu links*/

nav a.nav-link {
  font-size: 0.9rem;
  letter-spacing: 0.04rem;
  cursor: pointer;
  color: var(--text-color);
}

nav ul li {
  text-transform: uppercase;
  color: var(--text-color);
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--text-color);
  /* color: var(--primary-white) !important; */
  /* border-top: 0.1875rem solid var(--primary-dark-bg); */
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 8px 1rem !important;
  height: 3rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem !important;
  }
}

.navbar-nav > li {
  transition: 0.8s;
}

.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  color: var(--heading) !important;
}

.navbar-nav > li > a:hover {
  color: var(--heading) !important;
  font-weight: 600;
}

@media (max-width: 767px) {
  .mode-toggle-btn {
    margin-left: 4rem;
  }
}
