/*********************** journey *************************/
.journey {
  padding: 0.7rem;
  /* background: linear-gradient(to bottom, #ebf8dd, #fafafa); */
  background: var(--bg-color);
}

.journey-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.journey h1 {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--heading);
}

.journey-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--secondary-content);
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

@media (min-width: 769px) {
  .timeline-block-left .timeline-content {
    direction: rtl !important;
  }
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--secondary-content);
  background: var(--primary-red);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  font-size: 0.9rem;
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--secondary-content);
  direction: ltr;
}

@keyframes zoominoutsinglefeaturedtimeline {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--secondary-heading);
  font-weight: 700;
  font-size: 1.3rem;
  animation: zoominoutsinglefeaturedtimeline 2s infinite;
}

/*********************** MEDIA *************************/
@media (max-width: 768px) {
  .journey .container {
    padding-left: 0;
    margin-left: 5px;
  }
  .journey-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .journey h1 {
    margin-bottom: -25%;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
  .timeline-content h3 {
    font-size: 1.1rem;
  }
  .timeline-content {
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
  }
}
