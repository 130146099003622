/*********************** SKILLS *************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--bg-color);
  /* background: var(--primary-light-grey); */
}

.services h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .icon {
  font-size: 3.5rem;
  color: var(--heading) !important;
}

@keyframes zoominoutsinglefeaturedicon {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.services .box {
  height: auto;
  padding: 1rem 0.1rem;
  margin: 1.2rem;
  background: var(--box-grad);
  color: var(--text-color);
  border-bottom: 5px solid var(--heading);
  /* border-right: 1px solid var(--heading); */
  border-radius: 15%;
  transition: 0.2s ease-in-out;
}

.services .box h3 {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .services h1 {
    font-size: 1.5rem;
  }
  .services .box {
    transform: scale(0.8);
    padding: 15px 10px;
    margin: -3px;
  }
  .services .box:hover,
  .services .box:active {
    transform: scale(0.85) !important;
  }
}

.services .box:hover,
.services .box:active {
  transform: scale(1.1);
  background: linear-gradient(
    to bottom,
    var(--heading) 0.1%,
    var(--mode-color)
  );
  border-bottom: 5px solid var(--text-color);
  /* border-right: 1px solid var(--text-color); */
  cursor: pointer;
  box-shadow: 0 0 5px var(--text-color);
}

.services .box:hover .icon {
  color: var(--text-color) !important;
  transition: 0.3s ease-in-out;
}

strong,
.with {
  color: var(--heading);
}
