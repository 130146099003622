/*********************** CONTACT ME  *************************/
.contacts {
  background: var(--bg-color);
  padding: 3rem 0;
}

.contacts h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--heading);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--secondary-content);
  font-size: 1rem;
}

@media (max-width: 768px) {
  .contacts p {
    font-size: 0.8rem;
  }
}

/*********************** INPUTS  *************************/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--secondary-content-light);
  border-radius: 0;
  color: var(--secondary-heading);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--secondary-heading);
  border-bottom: 0.125rem solid var(--secondary-content);
}

.contacts textarea {
  height: 6rem !important;
}

.contact-btn {
  margin: 2rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  width: 0;
  top: -0.8rem;
  height: 0.125rem;
  background: var(--heading);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
  /* background: var(--secondary-content); */
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
  background: var(--primary-hover-red);
  text-align: left;
}

/**************** MEDIA ******************/
@media (max-width: 768px) {
  .contacts h1 {
    font-size: 1.5rem;
  }
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 1rem;
  }
  .success__msg {
    font-size: 0.9rem;
  }
}

.btn-full {
  /* margin: 1rem 0;
  position: absolute;
  /* top: 50%; */
  /* left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);  */
  /* width: 50%; */
  /* color: black; */
  box-shadow: 0 0 0px var(--mode-color);
  /* margin-bottom: 1rem; */
}

/* .btn-full:hover {
  width: 85%;
  transform: scale(1.1);
  color: black;
} */

.contact__img {
  max-height: 300px;
  margin: -2rem 0rem 0rem 5rem;
}

@media (max-width: 767px) {
  .contact__img {
    max-height: 200px;
    margin: 1rem 5rem 2rem 5rem;
  }
}
