/*********************** ABOUT ME *************************/

.about {
  padding-bottom: 2rem;
  /* background: linear-gradient(to right, #f1e5d6, #ffffff); */
  background: var(--bg-color);
}
.about__photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__profile-img {
  width: 15rem;
  border-radius: 25% 50%;
  padding: 0.5rem;
  margin-top: 3rem;
  cursor: pointer;
  /* border: 1px solid var(--heading); */
  animation: zoominoutsinglefeatured 2s infinite;
  opacity: 0.8;
}

.about__heading {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--heading);
  text-transform: uppercase;
  margin-bottom: 1.8rem;
}

/* .about__heading:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
} */

.about__content {
  color: var(--text-color-2);
  font-size: 0.95rem;
  letter-spacing: 0.02rem;
  /* position: relative; */
}

.about-div {
  /* background-image: url("../../images/about-dark2.svg");
  background-size: cover;
  opacity: 0.5; */
  overflow: hidden;
  position: relative;
}

.about-bg {
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  height: auto;
}

.about-btns {
  margin-top: 2.5rem;
}

@media (max-width: 768px) {
  .about__profile-img {
    width: 12rem;
    margin-top: 0rem;
  }
  .about__heading {
    font-size: 1.5rem;
    text-align: center;
  }
  .about__content {
    font-size: 0.85rem;
    margin: 0 1rem;
  }
  .about-bg {
    margin-top: 5rem;
    transform: scale(1.4);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}

.about__secondary-content {
  color: var(--secondary-content) !important;
}
