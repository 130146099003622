/*********************** PORTFOLIO *************************/
.portfolio-wrapper {
  background: var(--bg-color);
  padding: 3rem 0;
  font-size: 0.8rem;
}

.portfolio-wrapper h1 {
  color: var(--heading);
  font-size: 1.8rem;
  font-weight: 700;
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--heading);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  width: auto !important;
  margin: 1rem;
}

/*********************** OVERFLOW *************************/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--heading);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 50%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--heading);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 5rem;
}

/*********************** OVERFLOW BOX *************************/
.portfolio-image-popupbox {
  max-height: 15rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--heading);
}

.hyper-link:hover {
  color: blue;
}

/*********************** MEDIA *************************/

@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
  .portfolio-wrapper h1 {
    font-size: 1.5rem;
  }
}
@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100% !important;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}
@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90% !important;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
}
