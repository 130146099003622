/* **************** Header ********************** */

.header-wrapper {
  position: relative;
  /* background: var(--header-wall) no-repeat; */
  background: var(--bg-color);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

/* .header-wrapper__dark {
  position: relative;
  background: linear-gradient(135deg, #b6ffcc 10%, #99f3fd 100%);
  background-size: cover;
  background-position: center;
  height: 100vh;
} */

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-family: "Roboto Condensed", sans-serif; */
  z-index: 1;
}

.main-info h1 {
  background: var(--heading);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: var(--primary-orange); */
  text-transform: uppercase;
  font-size: 2.1rem;
  letter-spacing: 0.25rem;
  /* font-weight: 700; */
  margin-bottom: 1.2rem;
}

/* **************** TYPED TEXT ********************** */

.typed-text {
  font-size: 1.2rem;
  color: var(--secondary-content);
  margin: 2rem !important;
  /* font-family: "Syne Mono", monospace; */
  font-family: "DM Mono", monospace;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.5rem !important;
    letter-spacing: 0.2rem;
  }
  .typed-text {
    font-size: 0.8rem !important;
  }
}

/* **************** HEADER MAIN OFFER BUTTON ********************** */
/* 
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin-top: 2rem;
} */

.header__hello-img {
  max-height: 120px;
  margin: 2rem;
}

@media (max-width: 767px) {
  .header__hello-img {
    max-height: 100px;
    margin-bottom: 1rem;
  }
  .btn-grad {
    transform: scale(0.7);
  }
}

.symbols {
  background: rgb(51, 146, 94);
  text-transform: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: "DM Mono", monospace; */
}
