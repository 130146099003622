@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap"); */

body {
  font-family: "Lato", sans-serif !important;
}

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  /* --primary-transparent-black: rgb(255, 255, 255); */
  --primary-green: green;
  --primary-dark-bg: rgba(52, 58, 64, 0.9);

  /* --bg-color: linear-gradient(to right, #1c2c26, #373b44); */
  /* --bg-color: linear-gradient(to right, #1d2925, #2c2824); */
  /* --bg-color: linear-gradient(to right, #000000, #2c2427); */
  /* --bg-color: linear-gradient(to right, #414141, #272626); */
  --bg-color: #272626;

  /* --box-grad: linear-gradient(to bottom, #1c2c26, #50535a 90%); */
  /* --box-grad: linear-gradient(to bottom, #0c0f0e, #42413c 95%); */
  /* --box-grad: linear-gradient(to right bottom, #42413c, #000000 100%); */
  --box-grad: linear-gradient(to right bottom, #414141, #1f1c1c 100%);

  --mode-color: black;
  --text-color: white;
  --text-color-2: rgb(211, 197, 197);
  --name: #e0a188;
  --heading: #ff8c80;
  --secondary-heading: rgb(108, 195, 201);
  --secondary-content: #a1a1a1;
  --secondary-content-light: #636363;
  --gallery-transparent-bg: rgba(0, 0, 0, 0.6);

  --header-wall: url("https://res.cloudinary.com/sushant-thombare/image/upload/v1628270833/sushant-portfolio/header-wall_mmqb56.jpg");
  --gallery-wall: url("https://res.cloudinary.com/sushant-thombare/image/upload/v1628270872/sushant-portfolio/gallery-wall_fobzr8.jpg");
}

[data-theme="light"] {
  /* --bg-color: linear-gradient(to right, #b9e0b9, #99f3fd); */
  /* --bg-color: linear-gradient(to right, #b9e0b9, #fdf5f5); */
  /* --bg-color: linear-gradient(to right, #eaf8f1 0%, #fcf9c8 100%); */
  /* --bg-color: linear-gradient(to right, #eaf8f1 0%, #dbfcc8 100%); */
  /* --bg-color: linear-gradient(to right, #ebe6e7 0%, #f3f8c5 100%); */
  --bg-color: #d7ebdd;

  /* --box-grad: linear-gradient(to bottom, #e7fde7, #88fcdf 80%); */
  /* --box-grad: linear-gradient(to bottom, #aad1a9, #effaf7 80%); */
  --box-grad: linear-gradient(to left bottom, #aed1b8, #ffffff 90%);
  /* --box-grad: linear-gradient(to right bottom, #ffffff, #d1ecc0 60%); */
  --mode-color: white;
  --text-color: black;
  --text-color-2: rgb(68, 62, 62);
  --name: #ca4839;
  --heading: #ef4035;
  --secondary-heading: #e97f5f;
  --secondary-content: #535252;
  --secondary-content-light: #9e9d9d;
  /* --gallery-transparent-bg: rgba(100, 151, 156, 0.6); */
  --gallery-transparent-bg: rgba(175, 173, 137, 0.6);
}

.App {
  background-color: var(--page-color);
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* **************** PARTICLES JS ********************** */

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  /* .particles-canvas {
    display: none;
  } */
}

/* **************** GRAD BUTTON ********************** */

.btn-grad {
  background-image: linear-gradient(
    to right,
    #ff6e6e 0%,
    #bfe9ff 51%,
    #ff6e6e 100%
  );
}
.btn-grad {
  /* margin: 10px 0px; */
  font-size: 0.7rem;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.8s;
  background-size: 200% auto;
  color: rgb(255, 255, 255);
  /* box-shadow: 0 0 2px #eee; */
  border-radius: 10px;
  display: block;
  text-decoration: none;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  .btn-grad {
    margin: 15px -20px;
  }
}

.heading-hover:hover {
  -webkit-transform: skewY(2deg) skewX(15deg) scale(1.1);
          transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
}

.btn-outline {
  margin: 0.5rem;
  font-size: 0.7rem;
  padding: 0.6rem 1.3rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  /* background-size: 200% auto; */
  background-color: transparent;
  color: #ff8c80;
  color: var(--heading);
  /* box-shadow: 0 0 2px #eee; */
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  border: 1px solid #ff8c80;
  border: 1px solid var(--heading);
  /* backface-visibility: hidden; */
}

.btn-outline:hover {
  color: white !important;
  color: var(--text-color) !important;
  border: 1px solid black;
  border: 1px solid var(--mode-color);
  background: linear-gradient(
    to bottom,
    #ff8c80 0.01%,
    black);
  background: linear-gradient(
    to bottom,
    var(--heading) 0.01%,
    var(--mode-color)
  );
  /* box-shadow: 0 0 1px var(--text-color); */
  /* border: 0px; */
}

@media (max-width: 767px) {
  .btn-outline {
    font-size: 0.6rem;
    margin: 0.6rem;
    padding: 0.6rem 1.1rem;
  }
}

.centered {
  margin: 2rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* **************** Navbar ********************** */

nav {
  z-index: 2;
}

.navbar {
  background: var(--bg-color);
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  opacity: 0.8;
}

/* .bg-my-transparent {
  background-color: var(--primary-dark-bg) !important;
} */

.my-logo {
  font-family: "Righteous", cursive;
  font-size: 0.9rem;
  background: -webkit-linear-gradient(var(--heading), #e7ab98);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-img {
  max-height: 1.5rem;
}

@media (max-width: 768px) {
  .my-logo {
    font-size: 0.8rem;
  }
  .logo-img {
    height: 1.3rem;
  }
}

.logo {
  /* padding-top: 10px; */
  /* width: 6.25rem; */
}

/* Menu links*/

nav a.nav-link {
  font-size: 0.9rem;
  letter-spacing: 0.04rem;
  cursor: pointer;
  color: var(--text-color);
}

nav ul li {
  text-transform: uppercase;
  color: var(--text-color);
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--text-color);
  /* color: var(--primary-white) !important; */
  /* border-top: 0.1875rem solid var(--primary-dark-bg); */
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 8px 1rem !important;
  height: 3rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem !important;
  }
}

.navbar-nav > li {
  transition: 0.8s;
}

.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  color: var(--heading) !important;
}

.navbar-nav > li > a:hover {
  color: var(--heading) !important;
  font-weight: 600;
}

@media (max-width: 767px) {
  .mode-toggle-btn {
    margin-left: 4rem;
  }
}

/* **************** Header ********************** */

.header-wrapper {
  position: relative;
  /* background: var(--header-wall) no-repeat; */
  background: var(--bg-color);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

/* .header-wrapper__dark {
  position: relative;
  background: linear-gradient(135deg, #b6ffcc 10%, #99f3fd 100%);
  background-size: cover;
  background-position: center;
  height: 100vh;
} */

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-family: "Roboto Condensed", sans-serif; */
  z-index: 1;
}

.main-info h1 {
  background: var(--heading);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: var(--primary-orange); */
  text-transform: uppercase;
  font-size: 2.1rem;
  letter-spacing: 0.25rem;
  /* font-weight: 700; */
  margin-bottom: 1.2rem;
}

/* **************** TYPED TEXT ********************** */

.typed-text {
  font-size: 1.2rem;
  color: var(--secondary-content);
  margin: 2rem !important;
  /* font-family: "Syne Mono", monospace; */
  font-family: "DM Mono", monospace;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.5rem !important;
    letter-spacing: 0.2rem;
  }
  .typed-text {
    font-size: 0.8rem !important;
  }
}

/* **************** HEADER MAIN OFFER BUTTON ********************** */
/* 
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin-top: 2rem;
} */

.header__hello-img {
  max-height: 120px;
  margin: 2rem;
}

@media (max-width: 767px) {
  .header__hello-img {
    max-height: 100px;
    margin-bottom: 1rem;
  }
  .btn-grad {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
}

.symbols {
  background: rgb(51, 146, 94);
  text-transform: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: "DM Mono", monospace; */
}

/*********************** ABOUT ME *************************/

.about {
  padding-bottom: 2rem;
  /* background: linear-gradient(to right, #f1e5d6, #ffffff); */
  background: var(--bg-color);
}
.about__photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__profile-img {
  width: 15rem;
  border-radius: 25% 50%;
  padding: 0.5rem;
  margin-top: 3rem;
  cursor: pointer;
  /* border: 1px solid var(--heading); */
  -webkit-animation: zoominoutsinglefeatured 2s infinite;
          animation: zoominoutsinglefeatured 2s infinite;
  opacity: 0.8;
}

.about__heading {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--heading);
  text-transform: uppercase;
  margin-bottom: 1.8rem;
}

/* .about__heading:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
} */

.about__content {
  color: var(--text-color-2);
  font-size: 0.95rem;
  letter-spacing: 0.02rem;
  /* position: relative; */
}

.about-div {
  /* background-image: url("../../images/about-dark2.svg");
  background-size: cover;
  opacity: 0.5; */
  overflow: hidden;
  position: relative;
}

.about-bg {
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  height: auto;
}

.about-btns {
  margin-top: 2.5rem;
}

@media (max-width: 768px) {
  .about__profile-img {
    width: 12rem;
    margin-top: 0rem;
  }
  .about__heading {
    font-size: 1.5rem;
    text-align: center;
  }
  .about__content {
    font-size: 0.85rem;
    margin: 0 1rem;
  }
  .about-bg {
    margin-top: 5rem;
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.about__secondary-content {
  color: var(--secondary-content) !important;
}

/*********************** SKILLS *************************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--bg-color);
  /* background: var(--primary-light-grey); */
}

.services h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .icon {
  font-size: 3.5rem;
  color: var(--heading) !important;
}

@-webkit-keyframes zoominoutsinglefeaturedicon {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoominoutsinglefeaturedicon {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.services .box {
  height: auto;
  padding: 1rem 0.1rem;
  margin: 1.2rem;
  background: var(--box-grad);
  color: var(--text-color);
  border-bottom: 5px solid var(--heading);
  /* border-right: 1px solid var(--heading); */
  border-radius: 15%;
  transition: 0.2s ease-in-out;
}

.services .box h3 {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .services h1 {
    font-size: 1.5rem;
  }
  .services .box {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    padding: 15px 10px;
    margin: -3px;
  }
  .services .box:hover,
  .services .box:active {
    -webkit-transform: scale(0.85) !important;
            transform: scale(0.85) !important;
  }
}

.services .box:hover,
.services .box:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: linear-gradient(
    to bottom,
    var(--heading) 0.1%,
    var(--mode-color)
  );
  border-bottom: 5px solid var(--text-color);
  /* border-right: 1px solid var(--text-color); */
  cursor: pointer;
  box-shadow: 0 0 5px var(--text-color);
}

.services .box:hover .icon {
  color: var(--text-color) !important;
  transition: 0.3s ease-in-out;
}

strong,
.with {
  color: var(--heading);
}

/*********************** journey *************************/
.journey {
  padding: 0.7rem;
  /* background: linear-gradient(to bottom, #ebf8dd, #fafafa); */
  background: var(--bg-color);
}

.journey-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.journey h1 {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--heading);
}

.journey-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--secondary-content);
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

@media (min-width: 769px) {
  .timeline-block-left .timeline-content {
    direction: rtl !important;
  }
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--secondary-content);
  background: var(--primary-red);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  font-size: 0.9rem;
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--secondary-content);
  direction: ltr;
}

@-webkit-keyframes zoominoutsinglefeaturedtimeline {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoominoutsinglefeaturedtimeline {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--secondary-heading);
  font-weight: 700;
  font-size: 1.3rem;
  -webkit-animation: zoominoutsinglefeaturedtimeline 2s infinite;
          animation: zoominoutsinglefeaturedtimeline 2s infinite;
}

/*********************** MEDIA *************************/
@media (max-width: 768px) {
  .journey .container {
    padding-left: 0;
    margin-left: 5px;
  }
  .journey-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .journey h1 {
    margin-bottom: -25%;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
  .timeline-content h3 {
    font-size: 1.1rem;
  }
  .timeline-content {
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
  }
}

/*********************** PORTFOLIO *************************/
.portfolio-wrapper {
  background: var(--bg-color);
  padding: 3rem 0;
  font-size: 0.8rem;
}

.portfolio-wrapper h1 {
  color: var(--heading);
  font-size: 1.8rem;
  font-weight: 700;
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--heading);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  width: auto !important;
  margin: 1rem;
}

/*********************** OVERFLOW *************************/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--heading);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 50%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--heading);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 5rem;
}

/*********************** OVERFLOW BOX *************************/
.portfolio-image-popupbox {
  max-height: 15rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--heading);
}

.hyper-link:hover {
  color: blue;
}

/*********************** MEDIA *************************/

@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
  .portfolio-wrapper h1 {
    font-size: 1.5rem;
  }
}
@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100% !important;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}
@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90% !important;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
}

.LoadingSpinner_spinner__2Kyqv {
  display: inline-block;
  width: 50px;
  height: 50px;
}

.LoadingSpinner_spinner__2Kyqv:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid teal;
  border-color: teal transparent teal transparent;
  -webkit-animation: LoadingSpinner_spinner__2Kyqv 1.2s linear infinite;
          animation: LoadingSpinner_spinner__2Kyqv 1.2s linear infinite;
}

@-webkit-keyframes LoadingSpinner_spinner__2Kyqv {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes LoadingSpinner_spinner__2Kyqv {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*********************** CONTACT ME  *************************/
.contacts {
  background: var(--bg-color);
  padding: 3rem 0;
}

.contacts h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--heading);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--secondary-content);
  font-size: 1rem;
}

@media (max-width: 768px) {
  .contacts p {
    font-size: 0.8rem;
  }
}

/*********************** INPUTS  *************************/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--secondary-content-light);
  border-radius: 0;
  color: var(--secondary-heading);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--secondary-heading);
  border-bottom: 0.125rem solid var(--secondary-content);
}

.contacts textarea {
  height: 6rem !important;
}

.contact-btn {
  margin: 2rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  width: 0;
  top: -0.8rem;
  height: 0.125rem;
  background: var(--heading);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
  /* background: var(--secondary-content); */
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
  background: var(--primary-hover-red);
  text-align: left;
}

/**************** MEDIA ******************/
@media (max-width: 768px) {
  .contacts h1 {
    font-size: 1.5rem;
  }
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 1rem;
  }
  .success__msg {
    font-size: 0.9rem;
  }
}

.btn-full {
  /* margin: 1rem 0;
  position: absolute;
  /* top: 50%; */
  /* left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);  */
  /* width: 50%; */
  /* color: black; */
  box-shadow: 0 0 0px var(--mode-color);
  /* margin-bottom: 1rem; */
}

/* .btn-full:hover {
  width: 85%;
  transform: scale(1.1);
  color: black;
} */

.contact__img {
  max-height: 300px;
  margin: -2rem 0rem 0rem 5rem;
}

@media (max-width: 767px) {
  .contact__img {
    max-height: 200px;
    margin: 1rem 5rem 2rem 5rem;
  }
}

/**************** FOOTER ******************/

.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
}

.footer a:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--heading);
}

